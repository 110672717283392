const isDevelopmentEnv = process.env.NODE_ENV === 'development';

function gTag(): void {
  if (isDevelopmentEnv || !window) {
    return;
  }

  if (!window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
  }

  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export default gTag;
