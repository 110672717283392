import history from 'app/services/history';
import { Route } from 'app/contracts';
import toArray from 'app/utils/to-array';

import routes from '../router/routes';

import mountRoutePath from './mount-route-path';
import { NavigateToPath, Parameter, Method } from './contracts';

interface Navigate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
  (routeName: string, params?: Parameter): string;
}

const navigateToPath: NavigateToPath = (path: string, queryStrings: string[], replace: boolean) => (
  params: Parameter = {},
  method: Method = Method.push,
): void => {
  const mountedPath = mountRoutePath(path, params, queryStrings);

  if (!mountedPath) {
    return null;
  }

  if (replace || method === Method.replace) {
    return history.replace(mountedPath);
  }

  history.push(mountedPath);
};

const navigate: Navigate = (routeName: string, params: Parameter = {}): string | undefined => {
  const { path, queryStrings } = routes.find((route) => route.name === routeName) || {};

  if (!path) return;

  return mountRoutePath(toArray(path)[0], params, queryStrings)?.pathname;
};

routes.forEach(({ name, path, queryStrings = [], replace = false }: Route) => {
  navigate[name] = navigateToPath(toArray(path)[0] as string, queryStrings, replace);
});

export default navigate;
