import toArray from './to-array';

// eslint-disable-next-line @typescript-eslint/ban-types
type Pipeline = <T>(...pipes: Function[]) => (...params: unknown[]) => T;

/**
 * Function Pipeline
 * @example
 *  pipeline(
 *    (n) => n + 10,
 *    (n) => n * 2,
 *  )(10);
 *    // output 40;
 * @param {Function[]} pipes
 * @return {Function} - initial argument values
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const pipeline: Pipeline = <T>(...pipes: Function[]) => (...params: unknown[]): T =>
  pipes.reduce((value, pipe) => pipe(...toArray(value)), params);

export default pipeline;
