enum Method {
  push = 'push',
  replace = 'replace',
}

type Parameter = Record<string, string | number | boolean>;

interface NavigateToPathAction {
  (params?: Parameter, method?: Method): void;
}

interface NavigateToPath {
  (path: string, queryStrings: string[], replace: boolean): NavigateToPathAction;
}

interface Navigate {
  [key: string]: NavigateToPathAction;
}

export { Method };
export type { Parameter, NavigateToPathAction, NavigateToPath, Navigate };
