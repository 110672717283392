import React from 'react';
import Layout from 'app/screens/layout';
import Router from 'app/services/router';

interface RootScreen {
  requestedUrl?: string;
}

const Root: React.FunctionComponent<RootScreen> = (props: RootScreen) => {
  const { requestedUrl } = props;

  return (
    <Layout>
      <Router location={requestedUrl} />
    </Layout>
  );
};

export type { RootScreen };
export default Root;
