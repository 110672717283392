import React from 'react';
import SEO from 'app/components/seo';
import settings from 'configurations/app';
import classNames from 'app/utils/class-names';

import Sidebar from './sidebar';
import Header from './header';
import * as styles from './layout.scss';

interface LayoutScreen {
  children: React.ReactNode;
}

const scrollTopOffset = 60;

const Layout: React.FunctionComponent<LayoutScreen> = (props: LayoutScreen) => {
  const { children } = props;
  const localRefs = {
    content: React.useRef<HTMLDivElement>(),
    header: React.useRef<HTMLDivElement>(),
  };

  const handleOnScroll = (event: MouseEvent): void => {
    const { scrollTop } = event.target as HTMLDivElement;

    if (!localRefs.header.current) return;

    const method = scrollTop >= scrollTopOffset ? 'add' : 'remove';

    localRefs.header.current.classList[method](styles.header);
  };

  React.useEffect(() => {
    if (!localRefs.content.current) return;

    localRefs.content.current.addEventListener('scroll', handleOnScroll);

    return () => {
      localRefs.content.current.removeEventListener('scroll', handleOnScroll);
    };
  }, [localRefs.content]);

  return (
    <React.Fragment>
      <SEO title={settings.title} description={settings.meta.description} />
      <div className={classNames('container-fluid', 'h-100', styles.container)}>
        <div className="row h-100">
          <Sidebar />
          <div className={classNames(styles.content)} ref={localRefs.content}>
            <Header ref={localRefs.header} />
            <div className={classNames('container')}>{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export type { LayoutScreen };
export default Layout;
