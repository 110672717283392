import settings from 'configurations/app';
import is from 'app/utils/is';

import pkg from '../../../../package.json';

import gTag from './g-tag';

interface Parameters {
  lineUuid: string;
  lineName: string;
  username: string;
  userRoles: string[];
  language: string;
  authenticated: boolean;
}

let prevParams: Partial<Parameters> = {};

let initialized = false;

const init = (params: Partial<Parameters> = {}): void => {
  initialized = true;

  prevParams = params;

  gTag('js', new Date());
  gTag('config', settings.tokens.googleAnalytics, {
    sendPageView: true,
    transportType: 'beacon',
    appName: pkg.name,
    ...params,
  });
};

const refresh = (params: Partial<Parameters>): void => {
  if (!initialized || is.shallowEqual(params, prevParams)) {
    return;
  }

  init({ ...prevParams, ...params });
};

export { refresh };
export default init;
