import config from 'configurations/app';

import gTag from './g-tag';

const sendPageView = (title: string): void => {
  if (!window) return;

  gTag('config', config.tokens.googleAnalytics, {
    pagePath: window.location.pathname,
    pageLocation: window.location.href,
    pageTitle: title,
  });
};

export default sendPageView;
