import * as pathToRegexp from 'path-to-regexp';

import { Parameter } from './contracts';

const compileQueryStrings = (queryStrings: string[] = [], params: Parameter = {}): string => {
  const queries = queryStrings
    .reduce((acc, query) => {
      const key = query.replace(/[^a-zA-Z0-9-_]/g, '');
      const value = pathToRegexp.compile(`${query}?`)(params);

      if (value !== undefined && value !== null && value.toString().trim() !== '') {
        acc.push(`${key}=${value}`);
      }

      return acc;
    }, [] as string[])
    .join('&');

  return queries ? `?${queries}` : '';
};

export default compileQueryStrings;
