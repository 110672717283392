import React from 'react';
import classNames from 'app/utils/class-names';
import settings from 'configurations/app';

import * as styles from './header.scss';

// eslint-disable-next-line react/display-name
const Header = React.forwardRef<HTMLDivElement, Record<string, unknown>>((props, ref) => {
  return (
    <div className={classNames('container-fluid', styles.container)} ref={ref}>
      <div className={classNames('row', 'align-items-center')}>
        <div className={classNames('col-12', styles.title)}>{settings.title}</div>
      </div>
    </div>
  );
});

export default Header;
