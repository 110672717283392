import React from 'react';
import anime from 'animejs';
import settings from 'configurations/app';
import classNames from 'app/utils/class-names';
import Link from 'app/components/link/link';

import * as styles from './logo.scss';

const getInitials = (value: string): string =>
  value
    .split(' ')
    .map((text) => text.substring(0, 1))
    .join('');

const Logo: React.FunctionComponent<Record<string, unknown>> = () => {
  const shadowRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!shadowRef) return;

    const { width, height, top, left } = shadowRef.current.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;

    const duration = 400;
    const scaleFactor = 1.5;

    anime
      .timeline({
        targets: shadowRef.current,
        duration,
        top: [-(innerHeight / 2), top],
        left: [-(innerWidth / 2), left],
        width: [innerWidth * scaleFactor, width],
        height: [innerHeight * scaleFactor, height],
        borderRadius: [0, height * scaleFactor, { value: 0, duration: duration * 0.2 }],
        easing: 'cubicBezier(0.55, 0.085, 0.68, 0.53)',
        complete: () => {
          shadowRef.current.style = null;
        },
      })
      .add(
        {
          targets: shadowRef.current.querySelector('span'),
          opacity: [0, 1],
          translateY: { value: [-100, 0], easing: 'easeOutExpo' },
        },
        '+=300',
      );
  }, []);

  return (
    <div className={classNames(styles.logo)}>
      <Link href="/">
        <div className={classNames(styles.shadow)} ref={shadowRef}>
          <span>{getInitials(settings.title)}</span>
        </div>
      </Link>
    </div>
  );
};

export default Logo;
