import Loadable from 'react-loadable';
import { Route } from 'app/contracts';
import Loading from 'app/components/loading';

const NotFoundAsync = Loadable({
  loader: () => import(/* webpackChunkName: "not-found" */ './not-found'),
  loading: Loading,
  delay: 300,
});

const routes: Route[] = [
  {
    name: 'notFound',
    path: ['/not-found', '(.*)'],
    replace: true,
    component: NotFoundAsync,
  },
];

export default routes;
