import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import * as GA from 'app/services/google-analytics';
import getDisplayName from 'app/utils/get-display-name';
import is from 'app/utils/is';
import parse from 'app/utils/parse';

interface Options {
  /* Tracking action type. E.g. click, view, open, close */
  action: string;
  /** Property to intercept from the enhanced component */
  handler?: 'onClick' | 'onChange';
}

interface WithGaTracking extends Pick<Options, 'handler'> {
  /** Google Analytics tracking event */
  tracking?: GA.Event & {
    scope: string;
  };
}

const mountAction = (scope: string, action: string): string => parse.toKebabCase(`${scope} ${action}`);

const defaultOptions: Options = {
  action: 'click',
  handler: 'onClick',
};

const withGATracking = <T extends Record<string, unknown>>(
  Component: React.FunctionComponent<T>,
  options: Partial<Options> = defaultOptions,
): React.FunctionComponent<T & WithGaTracking> => {
  const { action, handler } = { ...defaultOptions, ...options };

  const GATrackingEvent: React.FunctionComponent<T & WithGaTracking> = (props: T & WithGaTracking) => {
    const { [handler]: onAction, tracking, ...rest } = props;

    const handleAction = (...args: unknown[]): void => {
      if (is.object(tracking) && Object.keys(tracking).length) {
        const { scope, ...trackedData } = tracking;

        GA.sendEvent(mountAction(scope, action), trackedData);
      }

      if (onAction && typeof onAction === 'function') {
        onAction(...args);
      }
    };

    const newProps = {
      [handler]: handleAction,
    };

    return <Component {...rest} {...newProps} />;
  };

  GATrackingEvent.displayName = `withGATracking(${getDisplayName(Component)})`;

  hoistNonReactStatic(GATrackingEvent, Component);

  return GATrackingEvent;
};

export { WithGaTracking };
export default withGATracking;
