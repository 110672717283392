import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import * as GA from 'app/services/google-analytics';
import Root from 'app/screens/root';

import './app/styles/base.scss';

GA.init();

// eslint-disable-next-line no-underscore-dangle
if (process.env.__SERVER__) {
  window.onload = () => {
    Loadable.preloadReady().then(() => {
      ReactDOM.hydrate(<Root />, document.getElementById('root'));
    });
  };
} else {
  ReactDOM.render(<Root />, document.getElementById('root'));
}
