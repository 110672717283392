const getDefaultParamValue = (name: string | number, path: string): boolean | string => {
  const param = path.split('/').find((p) => p.includes(`:${name}`));
  const match = param?.match(/\((?<default>[A-z0-9-]+)/) ?? undefined;

  if (!match) {
    return false;
  }

  return match.groups.default;
};

export default getDefaultParamValue;
