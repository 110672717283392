import React from 'react';
import classNames from 'app/utils/class-names';

import * as styles from './loading.scss';

const Loading: React.FunctionComponent<Record<string, unknown>> = () => {
  const label = 'loading...';

  return (
    <div className={classNames('row', 'align-items-center', styles.container)}>
      <div className="col-12">
        <div className={styles.spinner}>
          <div className={classNames(styles.circle)} />
          <div className={classNames(styles.circle)} style={{ animationDelay: '-1s' }} />
          <div className={classNames(styles.circle)} style={{ animationDelay: '-1.2s' }} />
        </div>
      </div>
      <div className={classNames('col-12', styles.status)}>{label}</div>
    </div>
  );
};

export default Loading;
