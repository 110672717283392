import React from 'react';
import { Helmet } from 'react-helmet';
import settings from 'configurations/app';

type SearchEngineOptimization = {
  title: string;
  description: string;
  keywords?: string;
  path?: string;
  schema?: string;
  contentType?: string;
};

const generateMetaTags = ({
  title,
  description,
  contentType,
  url,
  keywords,
}: SearchEngineOptimization & { url: string }): Record<string, string>[] => {
  return [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    // { itemprop: 'image', content: getAbsoluteUrl(SeoImage) },
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      name: 'twitter:title',
      content: `${title} ${title !== settings.meta.title ? `${settings.meta.separator} ${settings.meta.title}` : ''}`,
    },
    { name: 'twitter:description', content: description },
    // { name: 'twitter:image:src', content: getAbsoluteUrl(SeoImage) },
    {
      name: 'og:title',
      content: `${title} ${title !== settings.meta.title ? `${settings.meta.separator} ${settings.meta.title}` : ''}`,
    },
    { name: 'og:type', content: contentType },
    { name: 'og:url', content: url },
    // { name: 'og:image', content: getAbsoluteUrl(SeoImage) },
    // { name: 'og:image:type', content: 'image/jpeg' },
    // { name: 'og:image:width', content: '960' },
    // { name: 'og:image:height', content: '600' },
    { name: 'og:description', content: description },
    { name: 'og:site_name', content: `${settings.meta.title}` },
    { name: 'keywords', content: keywords },
  ];
};
const SEO: React.FunctionComponent<SearchEngineOptimization> = (props) => {
  const { title, description, path, schema, contentType, keywords } = props;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        itemscope: undefined,
        itemtype: `http://schema.org/${schema}`,
      }}
      title={title + (!title.includes(settings.meta.title) ? ` ${settings.meta.separator} ${settings.meta.title}` : '')}
      link={[{ rel: 'canonical', href: path }]}
      meta={generateMetaTags({
        title,
        description,
        contentType,
        url: path,
        keywords,
      })}
    />
  );
};

SEO.defaultProps = {
  path: settings.meta.url,
  schema: 'Person',
  contentType: 'website',
  keywords: 'marcos goncalves, software engineer, development, developer, developer website, software architect',
};

export type { SearchEngineOptimization };
export default SEO;
