import Loadable from 'react-loadable';
import { Route } from 'app/contracts';
import Loading from 'app/components/loading';

const HomeAsync = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './home'),
  loading: Loading,
  delay: 300,
});

const routes: Route[] = [
  {
    name: 'home',
    path: '/',
    exact: true,
    component: HomeAsync,
  },
];

export default routes;
