import React from 'react';
import withTracking from 'app/enhancers/hoc/with-ga-tracking';
import location from 'app/utils/location';
import classNames from 'app/utils/class-names';

import * as styles from './link.scss';

interface LinkElement extends Record<string, unknown> {
  /** URL that the hyperlink points to */
  href: string;
  /** The relationship of the linked URL as space-separated link types. */
  rel?: string;
  /** Where to display the linked URL, as the name for a browsing context (a tab, window, or iframe) */
  target?: '_self' | '_blank' | '_parent' | '_top';
  /** Hints at the linked URL’s format with a MIME type. */
  type?: string;
  /* Custom CSS class */
  className?: string;
  /** On Click callback */
  onClick?: (event: React.MouseEvent) => void;
  /** Link content */
  children: React.ReactNode;
}

export const Link: React.FunctionComponent<LinkElement> = (props: LinkElement) => {
  const { href, rel, target, type, onClick, className, children } = props;

  const handleOnClick = (event: React.MouseEvent): void => {
    if (href.startsWith('mailto:')) return;

    event.preventDefault();

    location.navigate(href, target);

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a href={href} rel={rel} type={type} className={classNames(styles.link, className)} onClick={handleOnClick}>
      {children}
    </a>
  );
};

export type { LinkElement };
export default withTracking<LinkElement>(Link);
