const pkg = require('../../package.json');

const { GOOGLE_ANALYTICS_ID } = process.env;

const settings = {
  /**
   * Application current version
   */
  version: '3.0.0',
  /**
   * Application name
   */
  title: pkg.author,
  /**
   * Application SEO
   */
  meta: {
    title: 'Marcos Gonçalves',
    description:
      'Software Engineer, Developer Advocate and essentially passionated about software development. Click to find out more!',
    separator: '|',
    url: 'http://thegoncalves.com/',
  },
  /**
   * Progressive Web Application configuration
   */
  /**
   * Assets manifest
   */
  assetsManifest: {
    /**
     * Assets manifest file name
     * Please, do not confuse with manifest.json
     */
    fileName: 'assets-manifest.json',
  },
  /**
   * Server Settings
   */
  server: {
    /**
     * http Host alias
     */
    host: '127.0.0.1',
    /**
     * http Port
     */
    port: '8080',
    /**
     * Opens Chrome after server start
     */
    openBrowser: true,
    /**
     * HTTP Server's log
     */
    log: {
      /**
       * Enable or Disable the Log
       */
      enabled: true,
      /**
       * Directory where logs file will be saved
       */
      directory: 'log',
      /**
       * Settings for log file rotation
       * @See {@Link https://github.com/iccicci/rotating-file-stream}
       */
      rotation: {
        /**
         * Rotation interval
         * E.g. Rotate every '1d'
         */
        interval: '1d',
      },
    },
  },
  /**
   * Build Settings
   */
  build: {
    /**
     * Build path
     */
    outputPath: './public',
    /**
     * Copy files or directories
     * to `buildOutputPath`
     * @see {@link https://github.com/webpack-contrib/copy-webpack-plugin}
     */
    copy: [
      { from: './src/app/assets/favicon/*', to: 'assets/', flatten: true },
      { from: './src/app/assets/robots.txt', to: '' },
      { from: './src/app/assets/browserconfig.xml', to: '' },
      { from: './src/app/assets/manifest.json', to: '' },
      { from: './src/app/assets/site.webmanifest', to: '' },
      { from: './src/app/assets/sitemap.xml', to: '' },
      // { from: './src/app/assets/images', to: 'assets/images/' },
      { from: './src/error.html', to: '' },
    ],
  },
  /**
   * App Tokens
   */
  tokens: {
    googleAnalytics: GOOGLE_ANALYTICS_ID,
  },
};

module.exports = settings;
