type classes = string | boolean;

/**
 * Conditional CSS class handler
 * @example
 * classNames(true && 'foo', false && 'bar');
 * // outputs: 'foo'
 * @param classes[]
 */
const classNames = (...classes: classes[]): string => classes.filter(Boolean).join(' ');

export default classNames;
