import Loadable from 'react-loadable';
import { Route } from 'app/contracts';
import Loading from 'app/components/loading';

const AboutAsync = Loadable({
  loader: () => import(/* webpackChunkName: "contact" */ './about'),
  loading: Loading,
  delay: 300,
});

const routes: Route[] = [
  {
    name: 'about',
    path: '/about',
    exact: true,
    component: AboutAsync,
  },
];

export default routes;
