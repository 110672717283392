import React from 'react';
import classNames from 'app/utils/class-names';

import Logo from './logo';
import Menu from './menu';
import * as styles from './sidebar.scss';

const Sidebar: React.FunctionComponent<Record<string, unknown>> = () => {
  return (
    <div className={classNames(styles.container)}>
      <Logo />
      <Menu />
    </div>
  );
};

export default Sidebar;
