import parse from 'app/utils/parse';
import is from 'app/utils/is';

import gTag from './g-tag';

type Event = {
  category?: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  callback?: () => void;
};

const parseToKebabCase = (value: string | null | undefined): string | undefined => {
  if (is.nullOrUndefined(value)) {
    return;
  }

  return parse.toKebabCase(value);
};

const sendEvent = (action: string, event: Event = {}): void => {
  gTag('event', parse.toKebabCase(action), {
    eventCategory: parseToKebabCase(event.category),
    eventLabel: parseToKebabCase(event.label),
    eventCallback: event.callback,
    value: event.value,
    nonInteraction: event.nonInteraction,
  });
};

export { Event };
export default sendEvent;
