import typeOf from './type-of';

const is = {
  nullOrUndefined: (value: unknown): boolean => value === null || value === undefined,
  object: (value: unknown): value is Record<string, unknown> =>
    value !== null && typeof value === 'object' && Object.prototype.toString.call(value) === '[object Object]',
  absoluteUrl: (url: string): boolean => /^(https?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i.test(url),
  externalUrl: (url: string): boolean => {
    const getDomain = (address: string): string | undefined =>
      /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.exec(address)?.[1];

    return getDomain(window.location.href) !== getDomain(url);
  },
  shallowEqual: (source: Record<string, unknown | unknown[]>, target: Record<string, unknown | unknown[]>): boolean => {
    if (typeOf(source) !== typeOf(target)) {
      return false;
    }

    return Object.keys(source).every((key) => {
      if (typeOf(source[key]) !== typeOf(target[key])) {
        return false;
      }

      if (Array.isArray(source[key])) {
        return (source[key] as unknown[]).length === (target[key] as unknown[]).length;
      }

      return source[key] === target[key];
    });
  },
};

export default is;
