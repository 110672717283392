import React from 'react';
import classNames from 'app/utils/class-names';
import toArray from 'app/utils/to-array';

import IconList from './list';
import * as style from './icon.scss';

export interface IconElement {
  /** Icon name */
  name: keyof typeof IconList;
  /** Icon family variant */
  variant?: 'fontawesome';
  /** Icon size */
  size?: number;
  /** Custom CSS class */
  className?: string | string[];
}

export const Icon: React.FunctionComponent<IconElement> = (props: IconElement) => {
  const { name, size, variant, className } = props;

  const customClass = classNames(
    `icon-${variant}`,
    `icon-${variant}-${name}`,
    style.icon,
    ...toArray<string>(className),
  );

  return <i className={customClass} style={{ fontSize: size }} />;
};

Icon.defaultProps = {
  variant: 'fontawesome',
};

export default Icon;
