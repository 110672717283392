import dot from '@cookbook/dot-notation';

import is from './is';
import pipeline from './pipeline';

const pullValue = <T>(value: T, key?: string): T =>
  is.object(value) ? dot.pick<T>(value as Record<string, unknown>, key) : value;

/**
 * Order By
 * @param {any[]} source - source element to be ordered
 * @param {string} key - object dot notation path to lead sorting. Required when source is an object.
 * @param {boolean} desc - order by descending
 * @param {boolean} length - order by length instead of value
 */
const orderBy = <T = Record<string, unknown>>(source: T[], key?: string, desc = false, length = false): T[] => {
  const isObject = is.object(source[0]);
  const getValueOrLength = <T = unknown>(val: T): T | number => (length ? val.toString().length : val);

  if (isObject && !key) {
    throw new Error(`Invalid argument value ("${key}"): "key" argument is mandatory when working with object arrays.`);
  }

  return source.sort((a, b) => {
    const left = pipeline<T>(pullValue, getValueOrLength)(a, key);
    const right = pipeline<T>(pullValue, getValueOrLength)(b, key);

    if (left > right) {
      return desc ? -1 : 1;
    }

    if (right > left) {
      return desc ? 1 : -1;
    }

    return 0;
  });
};

export default orderBy;
