import Loadable from 'react-loadable';
import { Route } from 'app/contracts';
import Loading from 'app/components/loading';

const ContactAsync = Loadable({
  loader: () => import(/* webpackChunkName: "contact" */ './contact'),
  loading: Loading,
  delay: 300,
});

const routes: Route[] = [
  {
    name: 'contact',
    path: '/contact',
    exact: true,
    component: ContactAsync,
  },
];

export default routes;
