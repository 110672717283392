import React from 'react';
import { Router as BrowserRouter, StaticRouter, Switch, Route as PublicRoute, RouteComponentProps } from 'react-router';
import history from 'app/services/history';
import toArray from 'app/utils/to-array';

import routes from './routes';

interface RouterElement {
  location?: string;
}

/* eslint-disable react/jsx-props-no-spreading */
const Router: React.FunctionComponent<RouterElement> = (props: RouterElement) => {
  const { location } = props;

  // eslint-disable-next-line no-underscore-dangle
  const RouterType: React.ComponentType<Record<string, unknown>> = process.env.__SERVER__
    ? StaticRouter
    : BrowserRouter;

  // eslint-disable-next-line no-underscore-dangle
  const routerProps = process.env.__SERVER__ ? { location } : { history };

  return (
    <RouterType {...routerProps}>
      <Switch>
        {routes.map((route) => {
          const { path, exact, strict, render, component: Component } = route;
          const Route = PublicRoute;

          return (
            <Route
              key={toArray(path).join('')}
              path={path}
              exact={exact}
              strict={strict}
              render={(properties: RouteComponentProps<unknown>) => {
                return render ? render({ ...properties }) : <Component {...properties} />;
              }}
            />
          );
        })}
      </Switch>
    </RouterType>
  );
};

export type { RouterElement };
export default Router;
