import { Search } from 'history';
import { pathToRegexp, match, MatchResult } from 'path-to-regexp';
import history from 'app/services/history';
import routes from 'app/services/router/routes';
import { createLocationDescriptor } from 'app/services/navigate';
import { Route } from 'app/contracts';
import is from 'app/utils/is';

const getRouteFromPath = (path: string): Route | undefined => {
  return routes.find((route) => Boolean(pathToRegexp(route.path).exec(path)));
};

const location = {
  get current(): Route {
    return getRouteFromPath(history.location.pathname);
  },
  get params(): Record<string, string> {
    const route = this.current;
    const { params = {} } = match(route.path, { decode: decodeURIComponent })(history.location.pathname) as MatchResult;

    return params as Record<string, string>;
  },
  navigate: (url: string, target: '_self' | '_blank' | '_parent' | '_top' = '_blank'): void => {
    const decodedUrl = decodeURIComponent(url);

    if (is.absoluteUrl(decodedUrl) && is.externalUrl(decodedUrl)) {
      window.open(decodedUrl, target);
      return;
    }

    let sanitizedUrl = is.absoluteUrl(decodedUrl) ? decodedUrl.replace(/^.*\/\/[^/]+/, '') : decodedUrl;
    const search: Search = /\?.+/g.exec(sanitizedUrl)?.[0] ?? '';

    sanitizedUrl = sanitizedUrl.replace(search, '');

    history.push(createLocationDescriptor(sanitizedUrl, search));
  },
};

export default location;
