/* eslint-disable global-require, @typescript-eslint/no-var-requires, import/no-extraneous-dependencies */
import merge from 'deepmerge';
import type { Route } from 'app/contracts';
import pipeline from 'app/utils/pipeline';
import orderBy from 'app/utils/order-by';
import toArray from 'app/utils/to-array';

const routes: Route[] = [];

if (process.env.NODE_ENV === 'test') {
  require('require-all')({
    dirname: `${process.cwd()}/src/app/screens`,
    recursive: true,
    filter: /routes\.(js|ts)$/u,
    resolve: (module: Record<string, unknown>) => {
      routes.push(module.default as Route);
    },
  });
} else {
  const context = require.context('../../screens', true, /routes\.(js|ts)$/u);

  context
    .keys()
    .map(context)
    .forEach((module: Record<string, unknown>) => routes.push(module.default as Route));
}

export default pipeline<Route[]>((list: Route[]) => {
  // moves generic route - from Error 404 - to the last position
  // so we avoid route mismatch. e.g. one route taking precedence over another
  const index = list.findIndex((r) => toArray(r.path).includes('(.*)'));

  list.push(list.splice(index, 1)[0]);

  return list;
})(orderBy<Route>(merge.all(routes), 'path', true, true));
