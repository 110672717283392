import Loadable from 'react-loadable';
import { Route } from 'app/contracts';
import Loading from 'app/components/loading';

const SkillsAsync = Loadable({
  loader: () => import(/* webpackChunkName: "skills" */ './skills'),
  loading: Loading,
  delay: 300,
});

const routes: Route[] = [
  {
    name: 'skills',
    path: '/skills',
    exact: true,
    component: SkillsAsync,
  },
];

export default routes;
