import { LocationDescriptorObject, LocationState, Search, Pathname } from 'history';
import history from 'app/services/history';

const createLocationDescriptor = <S = LocationState>(
  pathname: Pathname,
  search: Search = '',
): LocationDescriptorObject => ({
  pathname,
  search,
  state: {
    from: history.location,
  },
});

export default createLocationDescriptor;
