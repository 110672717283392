import React from 'react';
import anime from 'animejs';
import history from 'app/services/history';
import navigate from 'app/services/navigate';
import Icon, { IconList } from 'app/components/icon';
import Link from 'app/components/link';
import location from 'app/utils/location';
import classNames from 'app/utils/class-names';

import * as styles from './menu.scss';

type MenuItem = {
  icon: keyof typeof IconList;
  title: string;
  href: string;
  routeName?: string;
};

const menu: MenuItem[] = [
  {
    icon: IconList.user,
    title: 'About',
    href: navigate('about'),
    routeName: 'about',
  },
  {
    icon: IconList.star,
    title: 'Skills',
    href: navigate('skills'),
    routeName: 'skills',
  },
  {
    icon: IconList.newspaper,
    title: 'Blog',
    href: 'https://medium.com/nerdjacking',
  },
  {
    icon: IconList.github,
    title: 'GitHub',
    href: 'https://github.com/themgoncalves?utm_source=themgoncalves.com&utm_medium=sidebar_menu',
  },
  {
    icon: IconList['linkedin-in'],
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/in/themgoncalves/?utm_source=themgoncalves.com&utm_medium=sidebar_menu',
  },
  {
    icon: IconList['paper-plane'],
    title: 'Contact',
    href: 'mailto:contact@themgoncalves.com',
  },
];

const Menu: React.FunctionComponent<Record<string, unknown>> = () => {
  const [currentRouteName, updateRouteName] = React.useState<string>(location.current.name);
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!ref.current) return;

    anime({
      targets: ref.current.querySelectorAll('div'),
      translateY: [-20, 0],
      duration: 350,
      opacity: {
        value: [0, 1],
        easing: 'linear',
      },
      easing: 'easeOutElastic',
      delay: anime.stagger(60, { start: 400 }),
    });

    const unlisten = history.listen(() => {
      updateRouteName(location.current.name);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className={classNames(styles.container)} ref={ref}>
      {menu.map(({ icon, title, href, routeName }) => (
        <Link
          key={href}
          href={href}
          className={classNames(styles.item, routeName === currentRouteName && styles.active)}
        >
          <Icon name={icon} className={styles.icon} />
          <span className={styles.label}>{title}</span>
        </Link>
      ))}
    </div>
  );
};

export default Menu;
